import React, { useState, useEffect } from 'react';
import InstructionsPopup from './InstructionsPopup';
import Question from './Question';
import Result from './Results';
import axios from 'axios';
import swal from 'sweetalert';
import '../css/mcq.scss';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams for URL parameters
import { baseURL } from '../../Api';

const PracticeMcq = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [timeTaken, setTimeTaken] = useState({});
  const [startTime, setStartTime] = useState(null); // Initialize as null
  const [showPopup, setShowPopup] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [error, setError] = useState(null); // Add an error state
  const [overallTimeRemaining, setOverallTimeRemaining] = useState(30 * 60); // Total quiz time in seconds (e.g., 30 minutes)
  const { batchId, packageId } = useParams();
  const navigate = useNavigate();
  const [intervalId, setIntervalId] = useState(null); // Store interval ID
  const [packageDetails, setPackageDetails] = useState(null);
  const [packageStatus, setPackageStatus] = useState(null);

  const handlePurchase = () => {
    navigate(`/purchase/${packageId}`);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .post(
        `${baseURL}check-user-package`,
        { packageId: packageId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { status, message } = response.data;
        setPackageStatus({ status, message });
        if (status !== 'success') {
          swal({
            title: 'Not Purchased',
            text: 'You have not purchased this course yet. Would you like to purchase it?',
            icon: 'warning',
            buttons: ['CANCEL', 'BUY NOW'],
          }).then((willPurchase) => {
            if (willPurchase) {
              handlePurchase();
            } else {
              navigate(-1);
            }
          });
        } else {
          setPackageDetails(response.data.data);
          console.log('Package details:', response.data.data);
          fetchMCQData();
        }
      })
      .catch((error) => {
        console.error('Error fetching package details:', error);
        setError('Error fetching package details.');
        swal(
          'Error Validating Packages',
          'We could not verify your package/course. Please try again',
          'error'
        ).then(() => {
          navigate(-1);
        });
      });

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [packageId, navigate]);

  const fetchMCQData = () => {
    const token = localStorage.getItem('token');

    if (!batchId) {
      setError('Batch ID is missing from URL parameters');
      setLoading(false);
      return;
    }

    // Fetch data from the API when the component mounts
    axios
      .get(`${baseURL}mcqquestions/${batchId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      })
      .then((response) => {
        // Set the fetched questions data to the state
        if (response.data.status === 'success') {
          setOverallTimeRemaining(response.data.data.length * 60);
          setQuestions(response.data.data);
        } else {
          throw new Error(response.data.message || 'Failed to fetch questions');
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching questions:', error);
        setError('Failed to fetch questions. Please try again later.');
        setLoading(false);
      });
  };

  const startTimer = () => {
    const id = setInterval(() => {
      setOverallTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          handleSubmitTest();
          clearInterval(id);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    setIntervalId(id);
  };

  const handleStartTest = () => {
    setShowPopup(false);
    setStartTime(Date.now()); // Initialize the start time when the test starts
    startTimer(); // Start the overall timer
  };

  const handleSelectOption = (option) => {
    setAnswers({
      ...answers,
      [questions[currentQuestionIndex].id]: option,
    });
  };

  const handleNextQuestion = () => {
    const currentTime = Date.now();
    const questionId = questions[currentQuestionIndex].id;
    const questionTime = (currentTime - startTime) / 1000; // Time in seconds
    setTimeTaken({
      ...timeTaken,
      [questionId]: questionTime,
    });
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setStartTime(currentTime);
  };

  const handlePreviousQuestion = () => {
    const currentTime = Date.now();
    const questionId = questions[currentQuestionIndex].id;
    const questionTime = (currentTime - startTime) / 1000; // Time in seconds
    setTimeTaken({
      ...timeTaken,
      [questionId]: questionTime,
    });
    setCurrentQuestionIndex(currentQuestionIndex - 1);
    setStartTime(currentTime);
  };

  const handleSubmitTest = () => {
    const currentTime = Date.now();
    const questionId = questions[currentQuestionIndex].id;
    const questionTime = (currentTime - startTime) / 1000; // Time in seconds
    setTimeTaken({
      ...timeTaken,
      [questionId]: questionTime,
    });
    setShowResult(true);
    clearInterval(intervalId); // Clear the timer when the test is submitted
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (showPopup) {
    return <InstructionsPopup onClose={handleStartTest} />;
  }

  if (showResult) {
    return (
      <Result questions={questions} answers={answers} timeTaken={timeTaken} />
    );
  }

  return (
    <div className="practice-mcq">
      <div className="timer">
        Time Remaining: {Math.floor(overallTimeRemaining / 60)}:
        {overallTimeRemaining % 60 < 10
          ? `0${overallTimeRemaining % 60}`
          : overallTimeRemaining % 60}
      </div>
      {questions.length > 0 && (
        <Question
          question={questions[currentQuestionIndex]}
          onSelectOption={handleSelectOption}
          selectedOption={answers[questions[currentQuestionIndex].id]}
        />
      )}
      <div className="navigation-buttons">
        <div className="submit-button">
          <button onClick={handleSubmitTest}>Submit</button>
        </div>
        <div className="next-previous-buttons">
          {currentQuestionIndex > 0 && (
            <button onClick={handlePreviousQuestion}>Previous</button>
          )}
          {currentQuestionIndex < questions.length - 1 && (
            <button onClick={handleNextQuestion}>Next</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PracticeMcq;
