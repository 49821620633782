import React from 'react';

import '../css/style.scss';
import image404 from '../../assets/images/image404.png';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img
        src={image404}
        alt="Page not found"
        className="not-found-image"
      />
      <h1 className="not-found-title">Page Not Found</h1>
      <p className="not-found-text">
        Page you are looking for is not on our server yet.
      </p>
    </div>
  );
};

export default NotFound;
