import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import axios from 'axios';
import swal from 'sweetalert';
import { IoMdArrowBack } from 'react-icons/io';
import { baseURL } from '../../Api';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfViewer = () => {
  const { pdfUrl, packageID } = useParams();
  var decodedPdfUrl = pdfUrl;
  try {
    decodedPdfUrl = atob(pdfUrl);
  } catch (e) {
    decodedPdfUrl = pdfUrl;
  }

  console.log('Decoded PDF URL:', decodedPdfUrl);
  const [packageDetails, setPackageDetails] = useState(null);
  const [packageStatus, setPackageStatus] = useState(null);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState(null); // State to hold PDF data
  const navigate = useNavigate();

  const handlePurchase = () => {
    navigate(`/purchase/${packageID}`);
  };

  const handlegoback = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    const token = localStorage.getItem('token');

    axios
      .post(
        `${baseURL}check-user-package`,
        { packageId: packageID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { status, message } = response.data;
        setPackageStatus({ status, message });
        if (status !== 'success') {
          swal({
            title: 'Not Purchased',
            text: 'You have not purchased this course yet. Would you like to purchase it?',
            icon: 'warning',
            buttons: ['CANCEL', 'BUY NOW'],
          }).then((willPurchase) => {
            if (willPurchase) {
              handlePurchase();
            } else {
              navigate(-1);
            }
          });
        } else {
          setPackageDetails(response.data.data);
          console.log('Package details:', response.data.data);

          // Fetch PDF data after package verification
          fetchPdfData();
        }
      })
      .catch((error) => {
        console.error('Error fetching package details:', error);
        setError('Error fetching package details.');
        swal(
          'Error Validating Packages',
          'We could not verify your package/course. Please try again',
          'error'
        ).then(() => {
          navigate(-1);
        });
      });

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [packageID, navigate]);

  const fetchPdfData = () => {
    const token = localStorage.getItem('token');
    const pdfId = decodedPdfUrl; // Assuming pdfUrl is the encoded pdfId

    axios
      .post(
        'http://127.0.0.1:8000/api/get-pdf',
        { pdfId, packageId: packageID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === 'success') {
          setPdfData(data.binaryFile); // Store binary file data
          console.log('PDF binary data fetched successfully');
        } else {
          console.error('Error fetching PDF data:', message);
          setError('Error fetching PDF data.');
        }
      })
      .catch((error) => {
        console.error('Error fetching PDF data:', error);
        setError('Error fetching PDF data.');
      });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    console.log(`Document loaded with ${numPages} pages`);
  };

  return (
    <div className="normal-div">
      <div className="top-section">
        <div className="top-section-header">
          <h1>{packageDetails?.package_name || 'Loading...'}</h1>
        </div>
      </div>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        {error ? (
          <div className="error-message">
            <p>{error}</p>
            <button onClick={handlegoback}>
              <IoMdArrowBack /> Go Back
            </button>
          </div>
        ) : pdfData ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={`data:application/pdf;base64,${pdfData}`} />;
          </Worker>
        ) : (
          <div>Loading PDF...</div>
        )}
      </div>
      <p>
        Page {pageNumber} of {numPages || 'Loading...'}
      </p>
      <button className="floating-button-top" onClick={handlegoback}>
        <IoMdArrowBack className="home-btn-top" />
      </button>
    </div>
  );
};

export default PdfViewer;
