import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'; // Import Link component
import { baseURL, baseURLWithoutAPI } from '../../Api';
import { useNavigate } from 'react-router-dom';

const Subjects = () => {
  // State to store subjects data
  const [subjects, setSubjects] = useState([]);
  const { packageID, subjectId, topicId, subtopicId } = useParams();
  const navigate = useNavigate();

  const [packagedetails, setPackageDetails] = useState({});
  const [subjectdetails, setSubjectDetails] = useState({});




  useEffect(() => {
    // Retrieve token from localStorage
    const token = localStorage.getItem('token');
    // Fetch data from the API when the component mounts
    axios
      .get(
        `${baseURL}subsubtopics/${subtopicId}/${topicId}/${subjectId}/${packageID}`,
        {
          // Use packageID from the URL params
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request headers
          },
        }
      )
      .then((response) => {
        // Set the fetched subjects data to the state
        if (response.data.data.length === 0) {
          navigate(
            `/contents/0/${subtopicId}/${topicId}/${subjectId}/${packageID}`
          );
        }
        setPackageDetails(response.data.packagedetails);
        setSubjectDetails(response.data.subjectdetails);
        setSubjects(response.data.data);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching subjects:', error);
      });
  }, []);

  return (
    <div className="other-courses">
    <div className="previous-details">
      <div className="preimage">
        {packagedetails.package_image && (
          <img
            src={`${baseURLWithoutAPI}${packagedetails.package_image}`}
            alt={packagedetails.package_name}
          />
        )}
      </div>
      <div className="other-course-details">
        <p className="sticker">Top Rated Course </p>
        <p className="package-name">{packagedetails.package_name} </p>
        <h2 className="title center">{subjectdetails.name}</h2>
      </div>
    </div>
    <h2 className="title center">Sub-Sub-Topics</h2>

      <div className="subject-list">
        {/* Map through subjects data and render each subject item */}
        {subjects.map((subject) => (
          <Link
            key={subject.subject_id}
            to={`/contents/${subject.sub_sub_topic_id}/${subject.sub_topic_id}/${subject.topic_id}/${subject.subject_id}/${subject.package_id}`} // Set the path including subject ID and package ID
            className="subject-item"
          >
            <div className="subject-image">
              <img
                src={`${baseURLWithoutAPI}${subject.icon_url}`}
                alt={subject.name}
              />
            </div>
            <div className="subject-details">
              <h2 className="subject-title">{subject.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Subjects;
