import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaAngleRight } from 'react-icons/fa';
import { baseURL, baseURLWithoutAPI } from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { MdHome } from 'react-icons/md';

// import '../css/contents.scss'; // Make sure you have this CSS/SCSS file

const Contents = () => {
  const [activeTab, setActiveTab] = useState('ALL');
  const { packageID, subjectId, topicId, subtopicId, subsubtopicId } =
    useParams();

  const [packagedetails, setPackageDetails] = useState({});
  const [subjectdetails, setSubjectDetails] = useState({});
  const [topicdetails, setTopicDetails] = useState({});

  const navigate = useNavigate();

  const base64EncodeUrl = (url) => {
    const encodedUrl = btoa(url);
    return encodedUrl;
  };

  const [contentData, setContentData] = useState({
    pdf: [],
    video: [],
    mcq: [],
  });

  useEffect(() => {
    axios
      .get(
        `${baseURL}contents/${subsubtopicId}/${subtopicId}/${topicId}/${subjectId}/${packageID}`
      )
      .then((response) => {
        setContentData(response.data.data);
        setPackageDetails(response.data.packagedetails);
        setSubjectDetails(response.data.subjectdetails);
        setTopicDetails(response.data.topicdetails);
      })
      .catch((error) => {
        console.error('Error fetching content data:', error);
      });
  }, [subsubtopicId, subtopicId, topicId, subjectId, packageID]);

  const renderContent = () => {
    switch (activeTab) {
      case 'ALL':
        return renderList([
          ...contentData.pdf,
          ...contentData.video,
          ...contentData.mcq,
        ]);
      case 'Ebook':
        return renderList(contentData.pdf);
      case 'Video':
        return renderList(contentData.video);
      case 'Test':
        return renderList(contentData.mcq);
      default:
        return null;
    }
  };

  const renderList = (items) => {
    console.log(items);
    if (items.length === 0) {
      return <p className="no-data">No data</p>;
    }

    return (
      <div className="item-list">
        <ol>
          {items.map((item) => (
            <li key={item.id}>
              <a
                className="items"
                href={
                  item.data_type === 'video'
                    ? `/viewVideo/${getVideoId(item.file_url)}/${packageID}`
                    : item.data_type === 'pdf'
                    ? `/pdfViewer/${encodeURIComponent(item.id)}`
                    : `/mcqtest/${item.id}/${packageID}`
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  if (item.data_type === 'video') {
                    const videoId = getVideoId(item.file_url);
                    if (videoId) {
                      navigate(`/viewVideo/${videoId}/${packageID}`);
                    }
                  } else if (item.data_type === 'mcq') {
                    navigate(`/mcqtest/${item.id}/${packageID}`);
                  } else if (item.data_type === 'pdf') {
                    // navigate(
                    //   `/pdfViewer/${base64EncodeUrl(baseURLWithoutAPI + item.file_url)}/${packageID}`
                    // );
                    navigate(`/pdfViewer/${item.id}/${packageID}`);
                  }
                }}
              >
                <div className="tab-contents">
                  {activeTab === 'ALL' ? (
                    <p className="content-title">
                      [{item.data_type.toUpperCase()}] {item.title}
                    </p>
                  ) : (
                    <p className="content-title">{item.title}</p>
                  )}
                </div>
                <div className="icon">
                  <FaAngleRight />
                </div>
              </a>
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const getVideoId = (url) => {
    const videoUrl = new URL(url);
    if (videoUrl.hostname === 'youtu.be') {
      return videoUrl.pathname.substring(1);
    }
    return videoUrl.searchParams.get('v');
  };

  return (
    <div className="root-content">
      <div className="top-section">
        <div className="top-section-header">
          <h1>{packagedetails.package_name}</h1>
        </div>
        <div className="top-section-content">
          <ul>
            <li>0% completed</li>
            <li>0/{contentData.pdf.length} Ebook</li>
            <li>0/{contentData.video.length} Video</li>
            <li>0/{contentData.mcq.length} Test</li>
          </ul>
        </div>
      </div>
      <div className="tab-navigation">
        <button
          className={activeTab === 'ALL' ? 'activetab' : ''}
          onClick={() => setActiveTab('ALL')}
        >
          ALL
        </button>
        <button
          className={activeTab === 'Ebook' ? 'activetab' : ''}
          onClick={() => setActiveTab('Ebook')}
        >
          Ebook
        </button>
        <button
          className={activeTab === 'Video' ? 'activetab' : ''}
          onClick={() => setActiveTab('Video')}
        >
          Video
        </button>
        <button
          className={activeTab === 'Test' ? 'activetab' : ''}
          onClick={() => setActiveTab('Test')}
        >
          Test
        </button>
      </div>
      <div className="content-tab-layout">{renderContent()}</div>
      <button
        className="floating-button"
        onClick={() => navigate('/dashboard')}
      >
        <MdHome className="home-btn" />
      </button>
    </div>
  );
};

export default Contents;
