// ViewVideo.js
import React, { useState, useEffect } from 'react';
import { baseURL } from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import '../css/viewvideo.scss';
import { IoMdArrowBack } from 'react-icons/io';

const ViewVideo = () => {
  const { videoId, packageID } = useParams();
  const navigate = useNavigate();
  const [packageStatus, setPackageStatus] = useState(null);
  const [videoDetails, setVideoDetails] = useState({
    title: '',
    description: '',
  });

  const handlePurchase = () => {
    navigate(`/purchase/${packageID}`);
  };

  const handlegoback = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // Check for enrollment from the API
  useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .post(
        `${baseURL}check-user-package`,
        { packageId: packageID },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request headers
          },
        }
      )
      .then((response) => {
        const { status, message } = response.data;
        setPackageStatus({ status, message });
        if (status !== 'success') {
          swal({
            title: 'Not Purchased',
            text: 'You have not purchased this course yet. Would you like to purchase it?',
            icon: 'warning',
            buttons: ['CANCEL', 'BUY NOW'],
          }).then((willPurchase) => {
            if (willPurchase) {
              handlePurchase();
            } else {
              navigate(-1); // Navigate back to the previous page
            }
          });
        }
      })
      .catch((error) => {
        swal(
          'Error Validating Packages',
          'We could not verify your package/course. Please try again',
          'error'
        ).then(() => {
          navigate(-1); // Navigate back to the previous page
        });
      });

    // Get video details
    axios
      .get(`https://www.googleapis.com/youtube/v3/videos`, {
        params: {
          part: 'snippet',
          id: videoId,
          key: 'AIzaSyApi7JVaVDJUg882FnikMlZ826dcgtTbeA',
        },
      })
      .then((response) => {
        const { title, description } = response.data.items[0].snippet;
        setVideoDetails({ title, description });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [packageID, navigate, videoId]);

  if (packageStatus && packageStatus.status !== 'success') {
    return (
      <div>
        <p>
          You have not purchased this course yet. Would you like to purchase it?
        </p>
        <button onClick={handlePurchase}>Yes, take me to purchase</button>
      </div>
    );
  } else {
    return (
      <div className="view-video-content">
        <div className="top-nav">
          <div className="back-button" onClick={handlegoback}>
            <IoMdArrowBack />
            <span> Go Back</span>
          </div>
        </div>
        <div className="video-player">
          <iframe
            width="400px"
            height="225px"
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Video Player"
          ></iframe>
        </div>
        <div className="video-title">
          <h2>{videoDetails.title}</h2>
        </div>
        <div className="video-description">
          <p>{videoDetails.description}</p>
        </div>
      </div>
    );
  }
};

export default ViewVideo;
