import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import '../css/InstructionsPopup.scss'; // Import CSS file for styling

const InstructionsPopup = ({ onClose }) => {
  const navigate = useNavigate(); // Get the navigation object

  const handleCancel = () => {
    // if (navigate.length > 1) {
    //   // This line will cause an error because 'history' is not defined here.
    //   // You likely meant to use 'navigate' instead.
    //   navigate.goBack();
    // } else {
      navigate('/dashboard');
    // }
  };
  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Instructions</h2>
        <p>Read these instructions carefully before starting the test:</p>
        <ul>
          <li>This test consists of multiple-choice questions.</li>
          <li>Each question has four options, labeled A, B, C, and D.</li>
          <li>
            You must select the most appropriate answer from the given options.
          </li>
          <li>
            Ensure that you read each question carefully before selecting your
            answer.
          </li>
          <li>
            You cannot go back to a previous question once you have moved to the
            next question.
          </li>
          <li>
            You are advised to manage your time wisely to attempt all questions
            within the allocated time.
          </li>
        </ul>
        <h3>Marking Scheme:</h3>
        <ul>
          <li>Each correct answer will earn you a certain number of marks.</li>
          <li>There is no negative marking for incorrect answers.</li>
          <li>
            Unattempted questions will not affect your score positively or
            negatively.
          </li>
          <li>
            The final score is calculated based on the total number of correct
            answers and the time taken to attempt the questions.
          </li>
        </ul>
        <h3>Marks Calculation:</h3>
        <ul>
          <li>
            <strong>Correct Answers:</strong> For each correct answer, you will
            earn a certain number of marks.
          </li>
          <li>
            <strong>Time Factor:</strong> The time taken to complete the test
            also contributes to your score.
          </li>
          <li>
            <strong>Final Score:</strong> The final score is calculated based on
            a combination of the marks earned from correct answers and the time
            taken to attempt the questions.
          </li>
        </ul>
        <h3>Tips:</h3>
        <ul>
          <li>
            Read each question carefully and understand what is being asked.
          </li>
          <li>Eliminate incorrect options to narrow down your choices.</li>
          <li>
            Manage your time effectively to attempt all questions within the
            allocated time.
          </li>
          <li>Review your answers if time permits.</li>
        </ul>
        <div className="button-container">
          <button className="start-button" onClick={onClose}>
            Start Test
          </button>
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstructionsPopup;
