// Menu.jsx
import React, { useState } from 'react';
import { IoLogOutSharp } from 'react-icons/io5';
import { FaNoteSticky } from 'react-icons/fa6';
import { CgProfile } from 'react-icons/cg';
import { IoCloseSharp } from 'react-icons/io5';
import { FaBookReader } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';

import { Link } from 'react-router-dom';
import './../css/menu.scss';

const Menu = ({
  username,
  phoneNumber,
  isOpen,
  handleCloseMenu,
  avatarUrl,
}) => {
  return (
    <div>
      {/* Sliding Menu */}
      {isOpen && (
        <div className="menu">
          <div className="close-btn" onClick={handleCloseMenu}>
            <IoCloseSharp />
          </div>
          <div className="menu-user-info">
            <div className="menu-user-details">
              <img
                src={avatarUrl}
                className="image-avatar"
                alt="COMPLIT Education"
              />

              <h3>{username}</h3>
              <p>{phoneNumber}</p>
            </div>
          </div>
          <Link to="/dashboard" className="menu-item">
            <MdHome /> <span className="menu-text">Home</span>
          </Link>
          <Link to="/profile" className="menu-item">
            <CgProfile /> <span className="menu-text">Profile</span>
          </Link>
          <Link to="/mycourses" className="menu-item">
            <FaBookReader /> <span className="menu-text">My Courses</span>
          </Link>
          {/* <Link to="/settings" className="menu-item">
            <FaNoteSticky /> <span className="menu-text">Test Results</span>
          </Link> */}
          <Link to="/logout" className="menu-item">
            <IoLogOutSharp /> <span className="menu-text">Logout</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Menu;
