import Header from './componenets/js/Header';
import Footer from './componenets/js/Footer';
import './App.scss';
import Home from './componenets/js/Home';
import About from './componenets/js/About';
import ContactUs from './componenets/js/ContactUs';
import Dashboard from './componenets/js/Dashboard';
import Subjectss from './componenets/js/Subjectss';
import Contents from './componenets/js/Contents';
import Topics from './componenets/js/Topics';
import Signup from './componenets/js/UpdateProfile';
import SubTopics from './componenets/js/SubTopics';
import NotFound from './componenets/js/NotFound';
import SubSubTopics from './componenets/js/SubSubTopics';
import PracticeMcq from './componenets/js/PracticeMcq';
import ViewVideo from './componenets/js/ViewVideo';
import Purchase from './componenets/js/Purchase';
import PdfViewer from './componenets/js/PdfViewer';

import closeWifi from '../src/assets/images/close-wifi-512.webp';

import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MyCourses from './componenets/js/MyCourses';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Redirect to home page
    navigate('/');
  }, [navigate]);

  // Render null as Logout component does not have any UI
  return null;
}

function App() {
  // Create a navigate function using the useNavigate hook
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Handler for online event
    const handleOnline = () => {
      setIsOnline(true);
    };

    // Handler for offline event
    const handleOffline = () => {
      setIsOnline(false);
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Function to refresh the page
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="App">
      <Router>
        {/* <Header /> */}
        {!isOnline && (
          <div className="no-internet">
            {/* <img
              src={closeWifi}
              alt="No Internet"
              className="no-internet-image"
            /> */}
            <div className="nointernet-title">Woops !</div>
            <div className="nointernet-description">
              Looks like you are offline plese turn on your internet.
            </div>
            <button class="nointernet-button" onClick={handleRefresh}>
              Refresh
            </button>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/mycourses" element={<MyCourses />} />
          <Route
            path="/contents/:subsubtopicId/:subtopicId/:topicId/:subjectId/:packageID"
            element={<Contents />}
          />
          <Route path="/mcqtest/:batchId/:packageId" element={<PracticeMcq />} />
          <Route path="/subjects/:packageID" element={<Subjectss />} />
          <Route path="/topics/:subjectId/:packageID" element={<Topics />} />
          <Route
            path="/subtopics/:topicId/:subjectId/:packageID"
            element={<SubTopics />}
          />
          <Route
            path="/subsubtopics/:subtopicId/:topicId/:subjectId/:packageID"
            element={<SubSubTopics />}
          />
          <Route path="/*" element={<NotFound />} />{' '}
          {/* A fallback route for unmatched paths */}
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/viewVideo/:videoId/:packageID"
            element={<ViewVideo />}
          />
          <Route path="/purchase/:packageID" element={<Purchase />} />
          <Route path="/pdfViewer/:pdfUrl/:packageID" element={<PdfViewer />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
