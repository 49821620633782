import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/style.scss';
import splashscreenImage from '../../assets/images/abhigyan_academy_logo_small.webp';
import couplereading from '../../assets/images/couple-reading.png';
import splashscreenFooterImage from '../../assets/images/reding-footer-image.png';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

import { slide as Menu } from 'react-burger-menu';

import { baseURL } from '../../Api';

const Home = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [phoneNo, setPhoneNo] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      setShowLogin(true);
    }, 3000);

    // Check if token exists in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // Redirect to dashboard or popular exam page
      navigate('/dashboard');
    }

    return () => clearTimeout(timer);
  }, [navigate]);

  const handleRequestOTP = () => {
    // console.log(`phone_no: ${phoneNo}`);

    axios
      .post(`${baseURL}get-otp`, { phone_no: `${phoneNo}` })
      .then((response) => {
        if (response.data.status === 'success') {
          swal(
            'OTP Sent',
            'OTP sent to your registered mobile number',
            'success'
          );
          setShowLogin(false);
          setShowOTP(true);
        } else if (response.data.status === 'error') {
          swal('Error', response.data.message, 'error');
        } else {
          swal('Oops!!', 'Something went wrong', 'error');
        }
      })
      .catch((error) => {
        swal('Oops!', 'Something went wrong!\n' + error, 'error');
      });
  };

  const handleVerifyOTP = () => {
    const formData = {
      phone_no: `${phoneNo}`,
      otp: otp,
      fcm_token: 'NA',
    };

    axios
      .post(baseURL + 'loginwithOTP', formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === 'success') {
          const { token, user } = response.data;
          // Handle successful response
          localStorage.setItem('token', token); // Save token to localStorage
          localStorage.setItem('user', JSON.stringify(user)); // Save user to localStorage
          navigate('/dashboard');
        } else {
          swal('Invalid OTP', 'Please enter a valid OTP', 'error');
        }
      })
      .catch((error) => {
        // Handle error
        swal('Oops!', 'Something went wrong!', 'error');
        console.error('Error:', error);
      });
  };

  const handleSignup = () => {
    navigate('/signup'); // Redirect to signup page
  };

  return (
    <div>
      {showSplash ? (
        <div className="root-component">
          <div className="image center">
            {/* <img src={splashscreenImage} alt="complit-academy-app" /> */}
            <h1 className="text-center text-white text-customed-as-logo">
              COMPLIT <br />
              EDUCATION
            </h1>
          </div>
          <img
            className="footer-img"
            src={splashscreenFooterImage}
            alt="complit-academy-app"
          />
        </div>
      ) : (
        <div className="root-component-login">
          <div className="image loginscreen center">
            <img src={couplereading} alt="complit-academy-app" />
            <h1 className="title">Login or Sign up</h1>
            <div className="custom-form-group">
              {!showOTP ? (
                <>
                  <input
                    type="tel"
                    id="phone_no"
                    placeholder="Phone Number"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                  <div className="home-buttons">
                    <button className="signup" onClick={handleRequestOTP}>
                      Request OTP
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    id="otp"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <div className="home-buttons">
                    <button className="signup" onClick={handleVerifyOTP}>
                      Submit OTP
                    </button>
                  </div>
                </>
              )}
              <p className="noaccount">
                I agree to all the terms and conditions, set by the company.
              </p>
            </div>
          </div>
          <div className="footer"></div>
        </div>
      )}
    </div>
  );
};

export default Home;
