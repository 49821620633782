import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/signup.scss'; // Import your stylesheet
import { baseURL } from '../../Api';
import swal from 'sweetalert';
import Menu from './Menu'; // Import the Menu component
import { FaBars } from 'react-icons/fa';

const Updateprofile = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    fathersname: '',
    fathersphone: '',
    email: '',
    password: '',
    cpassword: '',
    dob: '', // Add DOB to formData
  });

  const [errors, setErrors] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close
  const [username, setUsername] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch profile data when component mounts
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    axios
      .get(`${baseURL}get-profile`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      })
      .then((response) => {
        const userData = response.data.data;
        setFormData({
          name: userData.name,
          phone: userData.phone,
          fathersname: userData.profile.fathersname,
          fathersphone: userData.profile.fathersphone,
          email: userData.email,
          dob: userData.dob, // Set DOB from response
        });
        setUsername(userData.name);
        setPhoneNumber(userData.phone);
        if (!userData.photo_url || userData.photo_url === null) {
          setAvatarUrl('https://avatar.iran.liara.run/public/boy?username=Ash');
        } else {
          setAvatarUrl(`${baseURL}${userData.photo_url}`);
        }
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset errors
    setErrors([]);
    const token = localStorage.getItem('token');

    axios
      .post(`${baseURL}update-user-profile`, formData,
        {
          headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      }
    )
      .then((response) => {
        // Handle successful response
        if (response.data.status === 'success') {
          const { token, user } = response.data;
          localStorage.setItem('token', token); // Save token to localStorage
          localStorage.setItem('user', JSON.stringify(user)); // Save user to localStorage
          swal('Update Successful', response.data.message, 'success');
        } else {
          swal('Update Failed', response.data.message, 'error');
        }
      })
      .catch((error) => {
        // Handle error response
        if (error.response && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          console.error('Error:', error);
        }
      });
  };

  return (
    <>
      <Menu
        username={username}
        phoneNumber={phoneNumber}
        isOpen={menuOpen}
        handleCloseMenu={toggleMenu}
        avatarUrl={avatarUrl}
      />

      <div className="hamburger-menu" onClick={toggleMenu}>
        <FaBars /> <span>Menu</span>
      </div>

      <div className="title">Your Profile</div>
      <p className="signup-qoute"></p>

      <form onSubmit={handleSubmit} className="login form_style2">
        {errors.length > 0 && (
          <div className="alert alert-danger">
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            required
            className="form-control"
            name="name"
            id="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            required
            className="form-control readonly"
            name="phone"
            id="phone"
            readOnly
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="fathersname">Father's Name</label>
          <input
            type="text"
            required
            className="form-control"
            name="fathersname"
            id="fathersname"
            placeholder="Father's Name"
            value={formData.fathersname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="fathersphone">Father's Phone</label>
          <input
            type="tel"
            required
            className="form-control"
            name="fathersphone"
            id="fathersphone"
            placeholder="Father's Phone"
            value={formData.fathersphone}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            required
            className="form-control"
            name="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="dob">Date of Birth</label>
          <input
            type="date"
            required
            className="form-control"
            name="dob"
            id="dob"
            value={formData.dob}
            onChange={handleChange}
          />
        </div>

        <div className="form-group signup-btn">
          <button
            type="submit"
            className="btn btn-default btn-block rounded-0 signup-btn-content"
            name="signup"
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default Updateprofile;
