import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import axios from 'axios';
import swal from 'sweetalert';
import { IoMdArrowBack } from 'react-icons/io';
import { baseURL } from '../../Api';
import '@react-pdf-viewer/core/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import '../css/pdfviewer.scss';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { MoreIcon } from '@react-pdf-viewer/toolbar';

// Import styles
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = () => {
  const { pdfUrl, packageID } = useParams();
  const decodedPdfUrl = pdfUrl; //atob(pdfUrl);
  console.log('Decoded PDF URL:', decodedPdfUrl);
  const [packageDetails, setPackageDetails] = useState(null);
  const [packageStatus, setPackageStatus] = useState(null);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState(null);
  const [pdfDetails, setPdfDetails] = useState(null);
  const navigate = useNavigate();

  const toolbarPluginInstance = toolbarPlugin();


  const handlePurchase = () => {
    navigate(`/purchase/${packageID}`);
  };

  const handlegoback = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
    const token = localStorage.getItem('token');

    axios
      .post(
        `${baseURL}check-user-package`,
        { packageId: packageID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { status, message } = response.data;
        setPackageStatus({ status, message });
        if (status !== 'success') {
          swal({
            title: 'Not Purchased',
            text: 'You have not purchased this course yet. Would you like to purchase it?',
            icon: 'warning',
            buttons: ['CANCEL', 'BUY NOW'],
          }).then((willPurchase) => {
            if (willPurchase) {
              handlePurchase();
            } else {
              navigate(-1);
            }
          });
        } else {
          setPackageDetails(response.data.data);
          console.log('Package details:', response.data.data);

          fetchPdfData();
        }
      })
      .catch((error) => {
        console.error('Error fetching package details:', error);
        setError('Error fetching package details.');
        swal(
          'Error Validating Packages',
          'We could not verify your package/course. Please try again',
          'error'
        ).then(() => {
          navigate(-1);
        });
      });

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [packageID, navigate]);

  const fetchPdfData = () => {
    const token = localStorage.getItem('token');
    const pdfId = decodedPdfUrl;

    axios
      .post(
        `${baseURL}get-pdf`,
        { pdfId, packageId: packageID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === 'success') {
          setPdfData(data.base64File);
          setPdfDetails(data.pdfDetails);
          getPdfPageCount(data.base64File);
          console.log('PDF base64 data fetched successfully');
        } else {
          console.error('Error fetching PDF data:', message);
          setError('Error fetching PDF data.');
        }
      })
      .catch((error) => {
        console.error('Error fetching PDF data:', error);
        setError('Error fetching PDF data.' + error);
      });
  };

  const getPdfPageCount = (base64Data) => {
    const binaryString = atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    loadingTask.promise
      .then((pdf) => {
        setNumPages(pdf.numPages);
        console.log(`Document loaded with ${pdf.numPages} pages`);
      })
      .catch((error) => {
        console.error('Error getting PDF page count:', error);
      });
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    toolbarPlugin: {
      buttons: {},
    },
  });

  return (
    <div className="normal-div" style={{ width: '100vw', height: '100vh' }}>
      <div className="top-section">
        <div className="top-section-header">
          <h1>{pdfDetails?.title || 'Loading...'}</h1>
        </div>
      </div>
      <div style={{ width: '100%', height: 'calc(100vh - 100px)' }}>
        {error ? (
          <div className="error-message">
            <p>{error}</p>
            <button onClick={handlegoback}>
              <IoMdArrowBack /> Go Back
            </button>
          </div>
        ) : pdfData ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={`data:application/pdf;base64,${pdfData}`}
              plugins={[defaultLayoutPluginInstance, toolbarPluginInstance]}
              style={{ width: '90%', height: '100%' }}
              MoreIcon={MoreIcon}
            />
          </Worker>
        ) : (
          <div>Loading PDF...</div>
        )}
      </div>
      <button className="floating-button-top" onClick={handlegoback}>
        <IoMdArrowBack className="home-btn-top" />
      </button>
    </div>
  );
};

export default PdfViewer;
