import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { correctAnswerMapping } from './correctAnswerMapping';
import { baseURL } from '../../Api';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import '../css/result.scss'; // Make sure you have this CSS/SCSS file
import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom'; // Import Link component

const Result = ({
  questions,
  answers,
  timeTaken,
  batchId,
  userId,
  category,
}) => {
  const [rank, setRank] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const totalQuestions = questions.length;
  const attempted = Object.keys(answers).length;
  const correct = questions.reduce((count, question) => {
    const selectedAnswer = answers[question.id];
    const correctAnswer = correctAnswerMapping[question.correct_answer];
    if (selectedAnswer !== undefined && selectedAnswer === correctAnswer) {
      return count + 1;
    }
    return count;
  }, 0);

  const skipped = totalQuestions - attempted;
  const wrong = attempted - correct;

  // Calculate the total time excluding skipped questions
  const totalTime = questions.reduce((total, question) => {
    const time = timeTaken[question.id];
    return total + (time !== undefined ? time : 0);
  }, 0);

  // Calculate scores
  const correctScore = (correct / totalQuestions) * 100;
  const attemptedScore = (attempted / totalQuestions) * 100;
  const maxTime = attempted * 180; // Only consider the maximum time for attempted questions
  const timeEfficiencyScore =
    Math.max(0, (maxTime - totalTime) / maxTime) * 100;

  // Calculate final score with new formula
  const finalScore =
    correctScore *
    (0.7 * correctScore + 0.2 * attemptedScore + 0.1 * timeEfficiencyScore);

  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  const getOptionLetter = (optionText) => {
    switch (optionText) {
      case 'option_1_text':
        return 'A';
      case 'option_2_text':
        return 'B';
      case 'option_3_text':
        return 'C';
      case 'option_4_text':
        return 'D';
      default:
        return 'Skipped';
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const baId = localStorage.getItem('batchId');

    const { id } = JSON.parse(user);

    console.log(baId);

    const saveResult = async () => {
      try {
        const response = await axios.post(
          `${baseURL}savMCQDetails`,
          {
            batchId: baId,
            correctans: correct,
            finalscore: finalScore,
            totalans: attempted,
            timetaken: totalTime,
            userId: id,
            rank: '', // This might be calculated server-side based on your application's logic
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === 'success') {
          setRank(response.data.rank);
        } else {
          setError(response.data.message || 'Failed to save results');
        }
      } catch (error) {
        console.error('Error saving results:', error);
        setError('Failed to save results. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    saveResult();
  }, [batchId, userId, correct, finalScore, attempted, totalTime, category]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const pieData = {
    labels: ['Correct', 'Skipped', 'Wrong'],
    datasets: [
      {
        data: [correct, skipped, wrong],
        backgroundColor: ['#4CAF50', '#FFC107', '#F44336'],
      },
    ],
  };

  const pieOptions = {
    cutout: '40%', // This creates the hole in the middle of the pie chart
  };

  return (
    <div className="result-card-1">
      <Link className="go-home" to="/dashboard">
        <MdHome />
        <span className="go-home-text">Go Home</span>
      </Link>
      <Tabs className="custom-tabs">
        <TabList className="custom-tablist">
          <Tab className="custom-mytab">Result Summary</Tab>
          <Tab className="custom-mytab">Explanations</Tab>
        </TabList>

        <TabPanel>
          <div className="result-summary">
            <div className="result-card">
              <h2>Result</h2>
              <div className="card-lower">
                <div className="card-left">
                  <p>
                    <span className="result-span">🖐 Total Questions: </span>
                    <span className="result-number">{totalQuestions}</span>
                  </p>
                  <p>
                    <span className="result-span">🙋‍♂️ Attempted: </span>
                    <span className="result-number">{attempted}</span>
                  </p>
                  <p>
                    <span className="result-span">🤠 Correct: </span>
                    <span className="result-number">{correct}</span>
                  </p>
                  <p>
                    <span className="result-span">🤕 Skipped: </span>
                    <span className="result-number">{skipped}</span>
                  </p>
                  <p>
                    <span className="result-span">❌ Wrong: </span>
                    <span className="result-number">{wrong}</span>
                  </p>
                  <p>
                    <span className="result-span">⏲ Total Time Taken: </span>
                    <span className="result-number">
                      {Math.round(totalTime)} seconds
                    </span>
                  </p>
                  <p>
                    <span className="result-span">✔️ Final Score: </span>
                    <span className="result-number">
                      {finalScore.toFixed(2)}
                    </span>
                  </p>
                </div>

                <div className="card-right">
                  {rank && (
                    <p>
                      <span className="rank-number">{rank}</span>
                      <span className="result-span">Rank</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="pie-chart">
              <Pie data={pieData} options={pieOptions} />
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="result-details">
            {questions.map((question, index) => (
              <div key={question.id} className="result-item">
                <h3>
                  <span className="question-number">{index + 1}:</span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(question.question_text),
                    }}
                  />
                </h3>
                {question.question_image &&
                  question.question_image !== 'NA' && (
                    <img src={question.question_image} alt="Question" />
                  )}
                <p>Options:</p>
                <ul className="res-options">
                  {question.option_1_text && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(`A: ${question.option_1_text}`),
                      }}
                    />
                  )}
                  {question.option_2_text && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(`B: ${question.option_2_text}`),
                      }}
                    />
                  )}
                  {question.option_3_text && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(`C: ${question.option_3_text}`),
                      }}
                    />
                  )}
                  {question.option_4_text && (
                    <li
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(`D: ${question.option_4_text}`),
                      }}
                    />
                  )}
                </ul>
                <p>
                  🤔 Selected :{' '}
                  {answers[question.id]
                    ? getOptionLetter(answers[question.id])
                    : 'Skipped 🙄'}
                </p>
                <p>🙂 Correct : {question.correct_answer}</p>
                {question.answer_explanation_text && (
                  <p
                    className="explanation-text"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(question.answer_explanation_text),
                    }}
                  />
                )}
                {question.answer_explanation_image &&
                  question.answer_explanation_image !== 'NA' && (
                    <img
                      src={question.answer_explanation_image}
                      alt="Explanation"
                    />
                  )}
              </div>
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Result;
