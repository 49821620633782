import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'; // Import Link component
import { baseURL, baseURLWithoutAPI } from '../../Api';
import { FaAngleRight } from 'react-icons/fa'; // Import FontAwesome and necessary icons
import '../css/subjects.scss';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [packagedetails, setPackageDetails] = useState({});
  const { packageID } = useParams();
  const navigate = useNavigate();


  const handlegoback = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get(`${baseURL}getSubjects/${packageID}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      })
      .then((response) => {
        setSubjects(response.data.data);
        setPackageDetails(response.data.packagedetails);
        console.log(response.data.packagedetails);
      })
      .catch((error) => {
        console.error('Error fetching subjects:', error);
      });
  }, [packageID]);

  return (
    <div className="other-courses">
      <div className="top-nav">
        <div className="back-button" onClick={handlegoback}>
          <IoMdArrowBack />
          <span> Go Back</span>
        </div>
      </div>
      <div className="previous-details">
        <div className="preimage">
          {packagedetails.package_image && (
            <img
              src={`${baseURLWithoutAPI}${packagedetails.package_image}`}
              alt={packagedetails.package_name}
            />
          )}
        </div>
        <div className="other-course-details">
          <p className="sticker">Top Rated Course </p>
          <h2 className="title center">{packagedetails.package_name}</h2>
        </div>
      </div>
      <h2 className="title center">Subjects</h2>

      <div className="other-courses-list">
        {subjects.map((subject) => (
          <div key={subject.subject_id} className="other-course">
            <Link
              to={`/topics/${subject.subject_id}/${subject.package_id}`}
              className="course-link"
            >
              <img
                className="course-thumbnail"
                src={`${baseURLWithoutAPI}${subject.icon_url}`}
                alt={subject.name}
              />
              <div className="course-details">
                <h3 className="course-title">{subject.name}</h3>
              </div>
              <div className="icon">
                <FaAngleRight />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subjects;
