import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'; // Import Link component
import { baseURL, baseURLWithoutAPI } from '../../Api';
import { FaAngleRight } from 'react-icons/fa'; // Import FontAwesome and necessary icons
import '../css/topics.scss';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';


const Subjects = () => {
  // State to store subjects data
  const [subjects, setSubjects] = useState([]);
  const { packageID, subjectId, topicId } = useParams();
  const [packagedetails, setPackageDetails] = useState({});
  const [subjectdetails, setSubjectDetails] = useState({});

  const navigate = useNavigate();

  const handlegoback = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    // Retrieve token from localStorage
    const token = localStorage.getItem('token');
    // Fetch data from the API when the component mounts
    axios
      .get(`${baseURL}topics/${subjectId}/${packageID}`, {
        // Use packageID from the URL params
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      })
      .then((response) => {
        // Set the fetched subjects data to the state
        setSubjects(response.data.data);
        setPackageDetails(response.data.packagedetails);
        setSubjectDetails(response.data.subjectdetails);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching subjects:', error);
      });
  }, []);

  return (
    <div className="other-courses">
      <div className="top-nav">
        <div className="back-button" onClick={handlegoback}>
          <IoMdArrowBack />
          <span> Go Back</span>
        </div>
      </div>
      <div className="previous-details">
        <div className="preimage">
          {packagedetails.package_image && (
            <img
              src={`${baseURLWithoutAPI}${subjectdetails.icon_url}`}
              alt={packagedetails.package_name}
            />
          )}
        </div>
        <div className="other-course-details">
          <p className="sticker">Top Rated Course </p>
          <p className="package-name">{packagedetails.package_name} </p>
          <h2 className="title center">{subjectdetails.name}</h2>
        </div>
      </div>
      <h2 className="title center">Topics</h2>

      <div className="other-courses-list">
        {/* Map through subjects data and render each subject item */}
        {subjects.map((subject) => (
          <div key={subject.subject_id} className="other-course">
            <Link
              key={subject.subject_id}
              to={`/subtopics/${subject.topic_id}/${subject.subject_id}/${subject.package_id}`} // Set the path including subject ID and package ID
              className="course-link"
            >
              <img
                className="course-thumbnail"
                src={`${baseURLWithoutAPI}${subject.icon_url}`}
                alt={subject.name}
              />
              <div className="course-details">
                <h3 className="course-title">{subject.name}</h3>
              </div>
              <div className="icon">
                <FaAngleRight />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subjects;
