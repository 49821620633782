import React from 'react';
import '../css/Question.scss'; // Import SCSS file for styling

const Question = ({ question, onSelectOption, selectedOption }) => {
  // Array of option objects with text, value, and image properties
  const options = [
    { text: question.option_1_text, value: 'option_1_text', image: question.option_1_image },
    { text: question.option_2_text, value: 'option_2_text', image: question.option_2_image },
    { text: question.option_3_text, value: 'option_3_text', image: question.option_3_image },
    { text: question.option_4_text, value: 'option_4_text', image: question.option_4_image },
  ];

  // Helper function to decode HTML entities
  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <div className="question">
      {/* Render question text with HTML decoding */}
      <h3 dangerouslySetInnerHTML={{ __html: decodeHtml(question.question_text) }} />
      
      {/* Render question image if it exists and is not 'NA' */}
      {question.question_image && question.question_image !== 'NA' && (
        <img src={question.question_image} alt="Question" />
      )}
      
      <div className="options">
        {options.map((option, index) => (
          <div key={index} className="option">
            {/* Radio input for each option */}
            <input
              type="radio"
              id={`option${index + 1}`}
              name="option"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => onSelectOption(option.value)}
            />
            <label htmlFor={`option${index + 1}`} dangerouslySetInnerHTML={{ __html: decodeHtml(option.text) }} />
            
            {/* Render option image if it exists and is not 'NA' */}
            {option.image && option.image !== 'NA' && (
              <img src={option.image} alt={`Option ${index + 1}`} className="option-image" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
