const ENV = 'production'; // 'development' or 'production'
let baseURL = '';
let baseURLWithoutAPI = '';
if (ENV === 'production') {
  baseURL = 'https://academy.complit.in/api/';
  baseURLWithoutAPI = 'https://academy.complit.in/';
} else if (ENV === 'devhome') {
  baseURL = 'http://192.168.1.3:8000/api/';
  baseURLWithoutAPI = 'http://192.168.1.3:8000/';
} else {
  baseURL = 'http://127.0.0.1:8000/api/';
  baseURLWithoutAPI = 'http://127.0.0.1:8000/';
}

export { baseURL, baseURLWithoutAPI };
