import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBars, FaAngleRight } from 'react-icons/fa';
import swal from 'sweetalert';
import { baseURL, baseURLWithoutAPI } from '../../Api';

import Menu from './Menu'; // Import the Menu component
// import '../css/myCourses.scss'; // Import your stylesheet

const MyCourses = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close
  const [username, setUsername] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage

    axios
      .get(`${baseURL}get-enrolled-courses`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
      })
      .then((response) => {

        console.log(response)
        setEnrolledCourses(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Function to toggle menu
  const toggleMenu = () => {
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUsername(userData.name);
      setPhoneNumber(userData.phone);
      if (!userData.photo_url || userData.photo_url === null) {
        // If photo_url is null or empty
        setAvatarUrl('https://avatar.iran.liara.run/public/boy?username=Ash');
      } else {
        // If photo_url is not null or empty
        setAvatarUrl(`${baseURL}${userData.photo_url}`);
      }
    }

    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Menu
        username={username}
        phoneNumber={phoneNumber}
        isOpen={menuOpen}
        handleCloseMenu={toggleMenu}
        avatarUrl={avatarUrl}
      />

      <div className="hamburger-menu" onClick={toggleMenu}>
        <FaBars /> <span>Menu</span>
      </div>

      <div className="other-courses">
        <h2 className="title center">Enrolled Courses</h2>
        <div className="other-courses-list">
          {enrolledCourses.map((course) => (
            <div key={course.id} className="other-course">
              <Link to={`/subjects/${course.id}`} className="course-link">
                <img
                  className="course-thumbnail"
                  src={`${baseURLWithoutAPI}${course.package_image}`}
                  alt="course-thumbnail"
                />
                <div className="course-details">
                  <h3 className="course-title">{course.package_name}</h3>
                </div>
                <div className="icon">
                  <FaAngleRight />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyCourses;
