import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaBars, FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Menu from './Menu'; // Import the Menu component
import { baseURL, baseURLWithoutAPI } from '../../Api';

import '../css/dashboard.scss';

const Dashboard = () => {
  const [banners, setBanners] = useState([]);
  const [username, setUsername] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [otherCourses, setOtherCourses] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu open/close
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseURL + 'appBanners')
      .then((response) => {
        console.log(response);
        if (response.status == 401) {
          // remove token and user from local storage
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/');
        } else setBanners(response.data.data);

        console.log(banners);
      })
      .catch((error) => {
        // Handle error
        navigate('/');
      });

    const token = localStorage.getItem('token');
    axios
      .get(baseURL + 'all-courses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status == 200) setOtherCourses(response.data.data);
        if (response.status == 401) {
          // remove token and user from local storage
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/');
        }
      })
      .catch((error) => {
        // Handle error
        navigate('/');
      });

    axios
      .get(baseURL + 'get-enrolled-courses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status == 401) {
          // remove token and user from local storage
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/');
        }

        if (response.data.status == 'success') {
          setEnrolledCourses(response.data.data);
        } else {
          // show no courses enrolled
          setEnrolledCourses([]);
        }
      })
      .catch((error) => {
        // Handle error
        navigate('/');
      });

    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUsername(userData.name);
      setPhoneNumber(userData.phone);
      if (!userData.photo_url || userData.photo_url === null) {
        // If photo_url is null or empty
        setAvatarUrl('https://avatar.iran.liara.run/public/boy?username=Ash');
      } else {
        // If photo_url is not null or empty
        setAvatarUrl(`${baseURL}${userData.photo_url}`);
      }
    }
  }, []);

  // Function to toggle menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="root-component">
      {/* Menu Component */}
      <Menu
        username={username}
        phoneNumber={phoneNumber}
        isOpen={menuOpen}
        handleCloseMenu={toggleMenu}
        avatarUrl={avatarUrl}
      />

      {/* Hamburger Icon */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <FaBars /> <span>Menu</span>
      </div>

      <div className="hi-name">
        <div className="hi-text">
          Hi <span> {username}</span>,
        </div>
        <p>Welcome Back !</p>
      </div>

      <div className="carousel-banners hide-on-large-screen">
        <Carousel showThumbs={false} showStatus={false}>
          {banners.map((banner) => (
            <div key={banner.id}>
              <img
                src={`${baseURLWithoutAPI}${banner.imageUrl}`}
                alt={banner.title}
              />
            </div>
          ))}
        </Carousel>
      </div>

      <div className="dashboard-content">
        <div className="enrolled-courses">
          {enrolledCourses.length > 0 ? (
            <h2 className="title center">Your Enrolled Courses</h2>
          ) : (
            <h2 className="title center "></h2>
          )}
          <div className="enrolled-courses-list">
            {enrolledCourses.map((course) => (
              <div key={course.id} className="enrolled-course">
                <Link to={`/subjects/${course.id}`} className="course-link">
                  <img
                    className="course-thumbnail"
                    src={`${baseURLWithoutAPI}${course.package_image}`}
                    alt="course-thumbnail"
                  />
                  <div className="course-details">
                    <h3 className="course-titled">{course.package_name}</h3>
                  </div>
                  <div className="icon">
                    <FaAngleRight />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="other-courses">
          <h2 className="title center">All Courses</h2>
          <div className="other-courses-list">
            {otherCourses.map((course) => (
              <div key={course.id} className="other-course">
                <Link to={`/subjects/${course.id}`} className="course-link">
                  <img
                    className="course-thumbnail"
                    src={`${baseURLWithoutAPI}${course.package_image}`}
                    alt="course-thumbnail"
                  />
                  <div className="course-details">
                    <h3 className="course-title">{course.package_name}</h3>
                  </div>
                  <div className="icon">
                    <FaAngleRight />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
